<template>
   <div class="page-container">
      <div class="banner-content">
         <div class="banner">
            <!--<img src="../../assets/banner/jishufuwu_bg_banner.min.jpg" alt="">-->
            <img :src="bannerUrl" alt>
         </div>
         <div class="page-title-content">
            <div class="page-title">
               <div class="first-title">技术服务</div>
               <div class="two-title">专注PE重包装袋领域</div>
            </div>
         </div>
      </div>
      <div class="page-content-div">
         <div class="page-content">
            <div class="jianjie-content">
               <div class="text-div">
                  <div class="title">1.网站信息填写</div>
                  <div class="text">确认物料性质与包装参数规格</div>

                  <div class="title">2.线上后续沟通</div>
                  <div class="text">确认使用环境与场景，调整包装参数，提供相关服务的技术解决方案</div>

                  <div class="title">3.确认印刷设计版面方案</div>
                  <div class="text">并和印刷制版厂直接对接交流，顾客、厂商、制版厂共同参与图案细节与校色核对，获取印刷方案最直接服务与价格</div>

                  <div class="title">4.正式签订合同</div>
                  <div class="text">正式确认包括交期与后续发货在内的服务内容、明确责任划分、制定厂商验收标准。而后签订正式合同</div>


                  <div class="title">5.进度汇报</div>
                  <div class="text">正式开工启动，关键工序汇报与监控</div>

                  <div class="title">6.产品交付</div>
                  <div class="text">产品生产完成，物流发货的同时根据事先明确的服务内容，将厂房的产品检测、性能单与第三方机构的性能检测文件票件交付</div>
                  <div class="title">7.后续与返单</div>
                  <div class="text">返单与二次报价</div>
                  <div class="text">在首单无出现其他服务问题情况下，返单的服务内容不会进行修改。工厂因此可根据顾客的生产计划适时调整发货周期。顾客有权对报价进行进一步协商，一般在产量与订单稳定提升的前提下，厂商人员能从工厂当中获取可观报价调整</div>
                  <div class="weight-text color-083a69">相关服务</div>
                  <div class="text">封口机、灌装机与相应自动化机组设备合作厂商：
                     <a href="http://www.hualian.biz" target="_blok" class="weight-text color-083a69" style="text-decoration: underline;font-weight: 500">华联机械集团</a>
                  </div>
               </div>

            </div>

         </div>
      </div>
   </div>
</template>

<script>
    export default {
        name: "index",
        data() {
            return {
                bannerUrl: ''
            }
        },
        created() {
            const banners = JSON.parse(localStorage.getItem('banners'));
            banners ? this.bannerUrl = banners[5]['imgUrl'] : this.bannerUrl = this.$url.backImg[5];

        }
    }
</script>

<style lang="scss" scoped>
   .page-container .banner{
      width: 100%;
   }
   .page-content-div{
      display: flex;
      justify-content: center;
      width: 100%;
      .page-content{
         width: 1200px;
      }
   }
   .jianjie-content{
      margin-top: 50px;
      .text-div{
         padding: 24px;
         border: 1px solid rgba(233, 233, 238, 1);
         line-height: 36px;
         color: rgba(114, 114, 122, 100);
         font-size: 16px;
         font-family: PingFangSC-Regular;
         .title{
            font-weight: 600;
            color: #000000ad;
         }
         .text{
            padding-left: 30px;
         }
      }
   }

   .weight-text{
      font-weight: 600;
      font-size: 17px;
      margin-top: 10px;
   }
   .color-083a69{
      color: #083a69;
   }
</style>